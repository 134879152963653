import React from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-react-intl"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import HeaderColor from "../../components/HeaderColor"
import HeaderCover from "../../components/HeaderCover"
import OrganizationLink from "../../components/OrganizationLink"
import Footer from "../../components/Footer"
import { Row, Col } from "antd"
import styled from "@emotion/styled"

const IntroContainer = styled.div`
  margin: 60px 0;
  .introWrapper {
    font-family: "Kanit", sans-serif;
    margin: 0 auto;
    text-align: center;
    span {
      font-weight: 300;
      font-size: 14px;
      color: #7497b2;
    }
    h2 {
      font-weight: 400;
      font-size: 38px;
      color: #181818;
      margin-bottom: 0;
    }
  }

  @media only screen and (min-width: 768px) {
    margin: 80px 0;
  }
`
const StructureContainer = styled.div`
  margin: 0 auto;
  text-align: center;
`

const OrganizationStructurePage = ({ data, intl }) => {
  const locale = intl && intl.locale ? intl.locale : `th`
  const { pageFields } = data
  const {
    seoTitle,
    seoDescription,
    coverImage,
    structureImage,
  } = pageFields.edges[0].node
  const getCoverImage = getImage(coverImage)
  const getStructureImage = getImage(structureImage)

  return (
    <Layout>
      <Seo lang={locale} title={seoTitle} description={seoDescription} />
      <HeaderColor />
      <HeaderCover
        bgImage={getCoverImage}
        coverText={intl.formatMessage({ id: "rootMenu3Sub1" })}
        secondLink="/about-us/"
        secondText={intl.formatMessage({ id: "rootMenu3" })}
      />
      <IntroContainer>
        <div className="gb-container">
          <Row justify="center">
            <Col span={24}>
              <div className="introWrapper">
                <span>{intl.formatMessage({ id: "rootMenu3Sub1" })}</span>
                <h2>{intl.formatMessage({ id: "rootMenu3Sub111" })}</h2>
              </div>
            </Col>
          </Row>
        </div>
      </IntroContainer>
      <StructureContainer>
        <div className="gb-container">
          <Row justify="center">
            <Col span={24}>
              <GatsbyImage image={getStructureImage} alt="" />
            </Col>
          </Row>
        </div>
      </StructureContainer>
      <OrganizationLink />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query OrganizationStructurePageQuery($locale: String) {
    pageFields: allContentfulOrganizationStructurePage(
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          seoTitle
          seoDescription
          coverImage {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
          structureImage {
            gatsbyImageData(width: 1530, placeholder: BLURRED, quality: 100)
          }
        }
      }
    }
  }
`

export default injectIntl(OrganizationStructurePage)
