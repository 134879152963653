import React from "react"
import { injectIntl } from "gatsby-plugin-react-intl"
import { ButtonTransparent } from "./Button"
import { Row, Col } from "antd"
import styled from "@emotion/styled"

const LinkContainer = styled.div`
  margin: 30px 0 90px 0;
  .columnWrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .leftColumn {
      width: 100%;
    }
    .rightColumn {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      align-self: normal;
      background: #ffffff;
      box-shadow: 0px 4px 46px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      margin-top: 30px;
      margin-left: 0;
      padding: 40px 0;
      .column {
        width: 100%;
        margin: 20px auto;
      }
    }
  }
  .contentWrapper {
    padding: 30px 15px;
    background: #7497b2;
    border-radius: 10px;
    font-family: "Kanit", sans-serif;
    h3 {
      font-weight: 400;
      font-size: 38px;
      color: #fff;
      text-align: center;
      margin-bottom: 5px;
    }
    p {
      font-weight: 400;
      font-size: 20px;
      color: #fff;
      text-align: center;
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .linkWrapper {
    font-family: "Kanit", sans-serif;
    margin: 0 auto;
    text-align: center;
    h4 {
      font-weight: 700;
      font-size: 24px;
      color: #181818;
      text-align: center;
      margin-bottom: 20px;
    }
  }

  @media only screen and (min-width: 576px) {
    margin: 80px 0 120px 0;
    .columnWrapper {
      .rightColumn {
        justify-content: flex-start;
        margin-top: 40px;
        .column {
          width: 50%;
          margin: 20px 0;
        }
      }
    }
    .contentWrapper {
      padding: 50px 60px;
      p,
      h3 {
        text-align: left;
      }
    }
    .linkWrapper {
      margin: 0 0 0 17.5%;
      text-align: left;
      h4 {
        text-align: left;
      }
    }
  }
  @media only screen and (min-width: 992px) {
    .contentWrapper {
      padding: 50px 120px;
    }
    .linkWrapper {
      margin: 0 0 0 25%;
    }
  }
  @media only screen and (min-width: 1200px) {
    .columnWrapper {
      .leftColumn {
        width: calc(40% - 15px);
      }
      .rightColumn {
        width: calc(60% - 15px);
        justify-content: space-around;
        align-self: stretch;
        margin-top: 0;
        margin-left: 30px;
        .column {
          width: auto;
          margin: 0;
        }
      }
    }
    .contentWrapper {
      padding: 50px;
    }
    .linkWrapper {
      margin: 0;
    }
  }
`

const OrganizationLink = ({ intl }) => {
  return (
    <LinkContainer>
      <div className="gb-container">
        <Row>
          <Col span={24}>
            <div className="columnWrapper">
              <div className="leftColumn">
                <div className="contentWrapper">
                  <h3>{intl.formatMessage({ id: "rootMenu3Sub1" })}</h3>
                  <p>{intl.formatMessage({ id: "siteTitle" })}</p>
                  <p>{intl.formatMessage({ id: "siteSlogan" })}</p>
                </div>
              </div>
              <div className="rightColumn">
                <div className="column">
                  <div className="linkWrapper">
                    <h4>{intl.formatMessage({ id: "subRootMenu311" })}</h4>
                    <ButtonTransparent
                      cta="/organization-management/organization-structure/"
                      text={intl.formatMessage({ id: "textMoreDetails" })}
                    />
                  </div>
                </div>
                <div className="column">
                  <div className="linkWrapper">
                    <h4>{intl.formatMessage({ id: "subRootMenu312" })}</h4>
                    <ButtonTransparent
                      cta="/organization-management/board/"
                      text={intl.formatMessage({ id: "textMoreDetails" })}
                    />
                  </div>
                </div>
                <div className="column">
                  <div className="linkWrapper">
                    <h4>{intl.formatMessage({ id: "subRootMenu313" })}</h4>
                    <ButtonTransparent
                      cta="/organization-management/executive/"
                      text={intl.formatMessage({ id: "textMoreDetails" })}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </LinkContainer>
  )
}

export default injectIntl(OrganizationLink)
